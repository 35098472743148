header {
    position: fixed;
    z-index: 25;
    padding: 1em 0 1em 0;
    /*backdrop-filter: blur(20px);*/
    background-color: var(--header-background);
    color: var(--header-text);
    border-radius: 0 0 6px;
}

header > .Navigation {
    display: inline-block;
    margin: 0 2em 0 0;
}

header a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    margin-right: 1em;
}

header li {
    display: inline;
}

header a:hover {
    border-bottom: 2px var(--color-green) solid;
}

.Header-Logotype {
    display: inline-block;
    font-family: "Source Code Pro", monospace;
    font-weight: 700;
    margin-left: 2em;
    margin-right: 1em;
    box-sizing: border-box;
}

.Header-Logotype a:hover {
    border: none;
    color: var(--color-green);
}

footer {
    font-family: "Source Code Pro", monospace;;
    font-size: 14pt;

    padding: 2em 4em 2em 2em;
    background-color: var(--footer-background);
    color: var(--footer-text);
}

.Footer-Sections {
    margin-top: 2em;
    margin-left: auto;
    width: fit-content;
}

footer .Navigation {
    list-style: none;
    line-height: 1.25;
    display: inline-block;
    vertical-align: top;
}

footer .Navigation > * {
    margin-right: 6rem;
}

footer .Navigation h1 {
    margin-bottom: .5em;
    font-size: 22pt;
}

footer a {
    color: inherit;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

.Footer-Copyright {
    margin: 4em auto auto;
    text-align: center;
}

