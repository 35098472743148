.ArticleNavbar {
    width: 100%;
    height: 2em;
    padding: .75em 0 .5em;

    margin: 0 0 1em;
    border-bottom: rgba(0, 0, 0, .2) 1px solid;
    border-radius: 6px 6px 0 0;
}

.ArticleNavbar>h1 {
    display: inline-block;
    margin-left: 1em;
}

.ArticleNavbar-Buttons {
    text-align: right;
    margin-right: .5em;
    float: right;
}

.ArticleNavbar-Buttons>div {
    display: inline-block;
    font-family: monospace;
    font-size: 16pt;

    height: 100%;
    width: 1.25em;

    text-align: center;
}

article {
    background-color: var(--article-background);
    box-shadow: rgba(0, 0, 0, .3) 0 0 5px;
    border-radius: 6px;
    max-width: 30em;
    margin-bottom: 4em;
    overflow-wrap: break-word;
}

.Article-Content {
    padding: 0 1em 2em 2em;
}

.Article-Content>h1 {
    margin-bottom: 1em;
}