
.Storyline {
    min-height: 100vh;
    width: 40vw;
    margin: auto;
    padding: 2em;
}

.Storyline > * {
    margin: 2em auto 0 auto;
}

.Banner + .Storyline {
    margin-top: -15vh;
}
